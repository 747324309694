<template>
  <div class="view-home">
    <div class="container questions-wrap pt-5">
      <!-- <div class="question-title">
        <h3 class="text-primary">{{ $t('about.title') }}</h3>
      </div> -->
      <div class="row mt-3">
        <div class="card test-about-card col-11 col-sm-6 mx-auto my-5">
          <div class="row">
            <!-- <div class="col-12 text-center pb-4">
              <img src="/images/tour2.png" alt="" width="140">
            </div> -->
            <div class="col-12">
              <p v-if="quiz[`descr_image_${test_lang}`]" style="text-align: center;">
                <img :src="quiz[`descr_image_${test_lang}`]" alt=""
                     style="max-width: 100%;">
              </p>
              <h3 class="text-center">{{ quiz['title_' + test_lang] }}</h3>


              <div class="text-center">
                <div class="btn btn-light" v-if="quiz.questions"><img src="/images/about-test-button1.svg"
                                                                      alt="">{{ quiz.questions.length }} {{
                    $t('about.questions-text') + (test_lang === 'ru' ? pluralizeRus(quiz.questions.length, ['', 'а', 'ов']) : '')
                  }}
                </div>
                <div class="btn btn-light"><img src="/images/about-test-button2.svg" alt="">{{ quiz.time }} минут</div>
              </div>
              <!-- <div class="description">
                {{ quiz['description_' + test_lang ]}}
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="question-content" v-html="quiz[`description_${test_lang}`]"></div>
      </div>
      <div class="row my-5">
        <div class="mx-auto">
          <router-link v-if="quiz.proctoring" to="/identification" tag="a" class="btn btn-default"><i
              class="el-icon-arrow-left mr-2"></i>{{ $t('about.back-button') }}
          </router-link>
          <button href="#" @click.prevent="openModal" class="btn btn-primary">{{ $t('about.begin-button') }}</button>

        </div>
      </div>
    </div>
    <b-modal id="confirm-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog" class="py-3">
          <button @click="$bvModal.hide('confirm-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3 class="mb-0">{{ $t('about.begin-modal-title') }}</h3>
          <div class="clearfix pt-3">
            <a @click="beginTest" class="btn btn-primary mx-2">{{ $t('about.begin-modal-button') }}</a>
            <a @click="$bvModal.hide('confirm-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('about.begin-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal size="lg" id="speed-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div class="py-3">
          <button @click="$bvModal.hide('speed-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h1 style="font-family: Roboto;color: #05458C; font-size: 26px">{{ $t('speed-test') }}</h1>
          <div v-if="speed>=1" class="wrapper-modal-speed" style="padding-top: 50px">
            <div class="column-modal-speed ">
              <img style="margin-left: auto; margin-right: auto" src="/images/wifi (1).svg">
            </div>
            <div class="column-modal-speed">
             <span style=" text-align: left !important; font-family: Roboto; color: black;font-size: 16px;  display: block;
    margin: auto;">
                 <span v-html="$t('internet-speed')"></span>
                  <br>{{ $t('min-speed') }} - <strong style="color: #2F80ED">1</strong> мбит/сек.
               <br>{{ $t('your-speed') }} - <strong style="color: #2F80ED">{{ speed }}</strong>  мбит/сек</span>
              <button @click="$bvModal.hide('speed-modal') " style="margin-top: 1rem" href="#" class="btn btn-primary">
                {{ $t('understand') }}
              </button>
            </div>
          </div>
          <div v-else class="wrapper-modal-speed" style="padding-top: 50px">
            <div class="column-modal-speed ">
              <img style="margin-left: auto; margin-right: auto" src="/images/wifi.svg">
            </div>
            <div class="column-modal-speed">
             <span style=" text-align: left !important; font-family: Roboto; color: black;font-size: 16px;  display: block;
    margin: auto;">
              <span v-html="$t('internet-speed-slow')"></span>
                  <br>{{ $t('min-speed') }} - <strong style="color: #2F80ED">1</strong> мбит/сек.
               <br>{{ $t('your-speed') }} - <strong style="color: #2F80ED">{{ speed }}</strong>  мбит/сек</span>
              <button @click="$bvModal.hide('speed-modal')" style="margin-top: 1rem" href="#" class="btn btn-primary">
                {{ $t('understand') }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  name: "About",
  components: {},
  data() {
    return {
      speed: 0,
      quiz: {},
      identification: false,
      test_lang: 'ru',
      locale: localStorage.getItem('locale') ?? 'ru',
    }
  },
  methods: {
    pluralizeRus(n, forms) {
      return n % 10 == 1 && n % 100 != 11
          ? forms[0]
          : (n % 10 >= 2 && n % 10 <= 4
          && (n % 100 < 10
              || n % 100 >= 20) ? forms[1] : forms[2]);
    },
    openModal() {
      if (!(this.getWebCam && this.getWebContents)) {
        setTimeout(() => {
          this.$router.go(0)
        }, 2000)
        return Vue.toastr({
          message: 'Внимание!',
          description: this.$t('access_to_full_screen_recording_cam'),
          type: 'error',
        });
      }


      if (this.quiz.proctoring && !this.identification) {
        this.$router.push({name: 'identification'})
      }
      this.$bvModal.show('confirm-modal');
    },
    beginTest() {
      this.$http.post(window.API_ROOT + '/api/store-network-speed', {
        'network_speed': this.speed,
        user_quiz_id: localStorage.getItem('user_quiz_id'),

      }).then((res) => {
        this.$router.push({path: '/question/1'})
      })
    },
    showResults(startTime, endTime) {

      var downloadSize = 24518; //bytes
      var duration = (endTime - startTime) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      var speedKbps = (speedBps / 1024).toFixed(2);
      var speedMbps = (speedKbps / 1024).toFixed(2);
      if (this.speed < speedMbps) {
        this.speed = speedMbps
      }

    },
    MeasureConnectionSpeed() {
      var imageAddr = "https://1000bala.elumiti.kz/images/1000-BALA-logo.svg";
      var startTime, endTime;
      var download = new Image();
      const showResult = this.showResults.bind(this)
      download.onload = function () {
        endTime = (new Date()).getTime();
        showResult(startTime, endTime);
      }
      startTime = (new Date()).getTime();
      var cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;
    }
  },
  computed: {
    getWebCam() {
      return this.$store.state.proctoring.permissionWebCam;
    },
    getWebContents() {
      return this.$store.state.proctoring.permissionWebContents;
    }
  },
  mounted() {
    this.MeasureConnectionSpeed()
    this.MeasureConnectionSpeed()
    this.MeasureConnectionSpeed()
    if (localStorage.getItem('quiz')) {
      this.quiz = JSON.parse(localStorage.getItem('quiz'));
      localStorage.setItem('display_results', this.quiz.display_results)
    } else {
      this.$router.push({name: 'not-found'})
    }
    if (localStorage.getItem('identification')) {
      this.identification = JSON.parse(localStorage.getItem('identification'));
    }
    if (localStorage.getItem('test_lang')) {
      this.test_lang = localStorage.getItem('test_lang');
      this.$i18n.locale = this.test_lang;
    }
    // this.$bvModal.show('speed-modal')


  },

}
</script>
<style scoped>
.column-modal-speed {
  text-align: left;
  width: 45%;
  display: flex;
  flex-direction: column;
}

.wrapper-modal-speed {
  justify-content: space-between;
  display: flex;
}

.test-about-card img {
  max-height: 100px;
}

@media screen and (max-width: 678px) {
  .question-content img {
    width: 100%;
  }
}
</style>
